
import React, { useEffect, useState, useRef } from 'react'
import logo from "../../images/logo.svg";
import tiktok from '../../images/webp/tiktok/colored.webp'
import youtube1 from '../../images/youtubelogo.svg'
import ticktok1 from '../../images/webp/tiktok/tiktoklogo1.svg'
import twitch from '../../images/webp/twitch/blank.png'
import twitch1 from '../../images/webp/twitch/colored.png'
import profit from '../../images/webp/beemiTrivia.webp'
import info1 from '../../images/webp/1.webp';
import info2 from '../../images/webp/2.webp';
import info3 from '../../images/webp/4.webp';
import info4 from '../../images/webp/3.webp';
import youtube from '../../images/youtubelogod.svg'
import VedioHeader from '../VedioHero';
import { useTranslation } from 'react-i18next';
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
import {  Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import english from '../../images/language.svg';
import amharic from '../../images/ethiopia.png'
import './animation.css'
import './textFlip.css'
import './wave.css'
import { message, Spin } from 'antd';
import { firestoreDb } from "../../firebase";
import {CONFIG} from '../utils/CONFIG';

import {
    collection,
    getDocs,
} from "firebase/firestore";

import one from '../../images/telegram.svg'
import icon from '../../images/beemiicons.svg'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import back from '../../images/arrow.svg';
import { useNavigate } from 'react-router-dom';
import bg from '../../images/decoration.svg';
import ParticleEffect from './ParticleEffect';
import AnimatedCarousel from './FlipTextEffect';
import Carousals from './ImageSwaper';
import AnimatedComments from './Animation/CommentAnimation';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div >
            <button className="invisible md:visible absolute top-1/2 transform -translate-y-1/2 left-0 md:left-[3%] p-3 mt-12" onClick={() => previous()}> <img src={back} alt='back' className='w-12 h-12 ' /></button>
            <button className=" invisible md:visible absolute top-1/2 transform -translate-y-1/2 right-0 md:right-[3%] p-5 mt-12" onClick={() => next()}> <img src={back} alt='forward' className='w-12 h-12 rotate-180' /></button>
        </div>
    );
};

const itemData = [

    {
        id: 2,
        title: 'twich',
        body: 'twichbody',
        icon: twitch,
        icona: twitch1,

        vedio: 'vediotwi',
        link: 'vediotwi',
        name: 'twitchname'

    }, {
        id: 1,
        title: 'tiktok',
        body: 'tiktokbody',
        icon: ticktok1,
        icona: tiktok,
        vedio: 'vediotik',
        link: 'vediotik',
        name: 'tiktokname'
    }, {
        id: 3,
        title: 'youtube',
        body: 'youtubeBody',
        icon: youtube1,
        icona: youtube,
        vedio: 'vedioyou',
        link: 'vedioyou',
        name: 'youtubename'

    }]

export default function Home() {
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = React.useState('1');
    const [activeLoading, setActiveLoading] = React.useState(false);
    const userLanguage = navigator.language || navigator.userLanguage;
    const [messageApi, contextHolder] = message.useMessage();
    const [data, setData] = React.useState(null)
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [showHeader, setShowHeader] = useState(true);
    let android = React.useRef()
    let ios = React.useRef()
    let windowsRef = React.useRef()
    const prevScrollY = useRef(0);
    const [contactError, setContactError] = useState(null);
    const [currentIndex, setItemIndex] = React.useState(0);


    let items1 = [
        { id: 1, title: t('title1'), body: t('body1'), image: info1 },
        { id: 2, title: t('title2'), body: t('body2'), image: info2 },
        { id: 3, title: t('title3'), body: t('body3'), image: info3 },
        { id: 4, title: t('title4'), body: t('body4'), image: info4 }
    ]

    const items = React.useRef(
        [
            {
                username: '@muaz_jemal',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/bdc5f7dad859fbae61c39cd237a79203~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=37691&refresh_token=ab02dfe4e3e918e1cdb6ac417626ab12&x-expires=1737190800&x-signature=XVR2%2BfLdHHiLxqPGDulENux%2Fkls%3D&shp=a5d48078&shcp=81f88b70',
            },
            {
                username: '@kbra_a_million',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/c8c30a3dec675e9a17880f77b86f7ca2~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=7370&refresh_token=a1cee68c07164b4aff28dda3f5699eb1&x-expires=1737190800&x-signature=yJWkQKg2sEcTy7G%2F%2BjeWtPX8YMs%3D&shp=a5d48078&shcp=81f88b70',
            },
            {
                username: '@4kilo_entertainment',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/8f45140aaeae6b9be9caa25161d300b5~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=60472&refresh_token=b1ddc9b3d14dce3ceadd831568b58845&x-expires=1737190800&x-signature=XeCcGg3qJ47%2B6tW6sWa5QZUrKFo%3D&shp=a5d48078&shcp=81f88b70',
            },
            {
                username: '@minte.digital',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7329091876861149189~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=38302&refresh_token=fa1d74eecf608d2352a3dbba7244bcbb&x-expires=1737190800&x-signature=YxX5RGHET3BO5dKoBcSzqx%2BH2Kc%3D&shp=a5d48078&shcp=81f88b70',
            },
            {
                username: '@official_ebbat',
                image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/a616d96fc9ae91f166b205d321dfc538~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=22005&refresh_token=0bc13541429a9a42b6c08181fb856244&x-expires=1737190800&x-signature=YZVedsiGC%2F%2BX2HV9tmwlOrv1q5o%3D&shp=a5d48078&shcp=81f88b70',
            }
        ]
    );

    const handleIndexChange = async (newIndex) => {
        setItemIndex(newIndex); // Update the state in the parent
    };


    const languageAssets = {
        en: {
            name: 'English',
            image: english,
        },
        am: {
            name: 'Amharic',
            image: amharic,
        },
    };

    const handleLanguageChange = (lang) => {
        console.log("Language changed to:", lang);

        i18n.changeLanguage(lang);
    };
    const languageItems = [
        {
            key: 'en',
            label: (
                <div className="flex items-center !header-top ">
                    <img
                        src={languageAssets.en.image}
                        alt="English"
                        className="w-6 h-6 object-contain rounded"
                    />
                    <span className="ml-2 text-sm font-medium">{languageAssets.en.name}</span>
                </div>
            ),
            onClick: () => handleLanguageChange("en")
        },
        {
            key: 'am',
            label: (
                <div className="flex items-center !header-top ">
                    <img
                        src={languageAssets.am.image}
                        alt="Amharic"
                        className="w-6 h-6 object-contain rounded"
                    />
                    <span className="ml-2 text-sm font-medium">{languageAssets.am.name}</span>
                </div>
            ),
            onClick: () => handleLanguageChange("am")
        },
    ];

    // Hide header on scroll
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;
            if (currentScrollY > prevScrollY.current && currentScrollY > 50) {
                setShowHeader(false);
            } else {
                setShowHeader(true);
            }
            prevScrollY.current = currentScrollY;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const isWebView = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
    };

    const isNotRootUrl = () => {
        return window.location.pathname !== '/';
    };

    React.useEffect(() => {
        if (isWebView() && isNotRootUrl()) {
            document.querySelector('#header').style.display = 'none';
        }
    }, []);


    async function fetchAllDocuments() {
        const collectionRef = collection(firestoreDb, "apk_link");
        try {
            const querySnapshot = await getDocs(collectionRef);
            const documents = querySnapshot.docs.map(doc => {
                if (doc.id == 'android') {
                    android.current = doc.data().active
                }
                if (doc.id == 'ios') {
                    ios.current = doc.data().active
                }
                if (doc.id == 'window') {
                    windowsRef.current = doc.data().active
                }
            });
            setData(documents)

            return documents;  // Returns an array of documents
        } catch (error) {
            console.error("Error fetching documents:", error);
            return [];  // Return an empty array or handle the error as needed
        }
    }

    const userEventsListenersGTAG = ({ action, category, label, value }) => {
        window.gtag("event", action, {
            event_category: category,
            event_label: label,
            value: value,
        });
    };

    // const pice = useParallax({
    //     translateY: [-500, 300],
    // });

    // const pice1 = useParallax({
    //     translateY: [-200, 1000],
    // });

    // const pice2 = useParallax({
    //     translateY: [-100, 150],
    // });

    const navigate = useNavigate();

    const navigateToNextPage = (username, photo) => {
        if (photo) {
            let newUser = username.replace('@', '');
            const url = `${newUser}`;
            console.log(url);
            
            navigate(url, { state: { photo } });
        } else {
            const url = `${username}`.replace('@', '');
            navigate(url);
        }
    };

    const setActiveTabFun = async (item) => {
        await setActiveLoading(true)
        await setActiveTab(item)
        await setActiveLoading(false)
    }

    const submitContactForm = async (event) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setContactError("Please enter a valid email address.");
            return;
        }

        try {
            const response = await fetch(`${CONFIG.backendUrl}/contactUs`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, comment }),
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || "Something went wrong");
            }

            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            setContactError('Failed To Send Email');
            console.error("Error:", error.message);
        }
    };

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const username = pathSegments[pathSegments.length - 1];
        if (username) {
            // console.log(`Username from URL: ${username}`);
        }
    }, []);

    useEffect(() => {
        setActiveTab(itemData[1])
        if (userLanguage.startsWith("am")) {
            i18n.changeLanguage('am')
        } else if (userLanguage.startsWith("en")) {
            i18n.changeLanguage('en')
        } else {
            console.log("Defaulting to English");
        }
    }, [])

    useEffect(() => {
        fetchAllDocuments()
    }, [])

    return (
        <div className='flex flex-col justify-center items-center  text-center overflow-x-hidden scroll-smooth mx-auto'>
            {/* Header Section */}
            <header
                id="header"
                className={`fixed  2xl:relative top-0 w-full bg-white shadow-md !z-[10000]  md:px-[8%] transform transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'}`}
            >
                <div className="mx-auto px-4 md:px- py-3 flex items-center justify-between !z-[10001]">
                    <a href="/" aria-label="Navigate to home page">
                        <img src={logo} alt="Logo" className="w-24 md:w-36" />
                    </a>
                    <div className="flex items-center space-x-6 ">
                        <a
                            href="#download"
                            aria-label="Download APK"
                            defer
                            className="flex items-center font-[Montserrat-Bold] text-white text-sm md:text-lg font-extrabold bg-gradient-to-r from-[#F2419C] to-[#F99F20] px-6 py-2 rounded-full shadow hover:shadow-lg transition-all duration-300"
                        >
                            {t('download')}
                        </a>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Dropdown: {
                                        zIndexPopup: 20000
                                        /* here is your component tokens */
                                    },
                                },
                            }}
                        >
                            <Dropdown getPopupContainer={(triggerNode) => document.body} menu={{ items: languageItems }} trigger={['click']} placement="bottomRight">
                                <button className="flex items-center focus:outline-none p-1 bg-gray-100 rounded-md  ">
                                    <img
                                        src={languageAssets[i18n.language]?.image || languageAssets['en'].image}
                                        alt={languageAssets[i18n.language]?.name || 'English'}
                                        className="w-6 h-6 object-contain rounded"
                                    />
                                    <CaretDownOutlined className="text-gray-500 ml-1 text-xs w-3" />
                                </button>
                            </Dropdown>
                        </ConfigProvider>
                    </div>
                </div>
            </header>
            {/* Hero Section */}
            <div className="flex md:flex-row  md:justify-around w-[100%]  flex-col-reverse justify-center items-center  px-[10%] sm:px-[10%] md:px-[10%] py-10 md:py-[5%]  md:items-center overflow-hidden bg-gradient-to-r from-[#F99F20] to-[#F2419C] " >
                <ParticleEffect particleCount={300} />
                {/* <img className="w-[15vw] absolute left-0  z-[9999] invisible" ref={pice.ref} src={imgPhone2} />
                <img className="w-[20vw] absolute right-0   z-[99] invisible" ref={pice1.ref} src={imgPhone} />
                <img className="w-[12vw] md:w-[12vw] xl:w-[12vw] invisible xl:top-[30px] 2xl:w-[2vw] absolute right-[45%] top-10 md:top-[-5%] 2xl:top-[50px]   z-10" ref={pice2.ref} src={icon} /> */}
                <div className="flex flex-col justify-center items-center  md:justify-around md:items-start p-0 md:mt-40 btn w-[50%]">
                    <div className='ml-2 min-w-[2ch] md:!min-w-[100ch] !min-h-[10px] md:ml-40 md:!min-h-[100px]'>
                        <AnimatedCarousel onIndexChange={handleIndexChange} />
                    </div>
                    <div className=" btn md:mt-10  md:ml-40">
                        <a aria-label="Contact us on telegram" href='https://t.me/beemi_app' className='bg-[#f0f0f000] border-2 mx-2 px-10 flex flex-row items-center justify-between py-2 mt-10 mb-32 md:mt-0 w-[200px] md:w-[250px] rounded-[50px] font-[Montserrat-Bold]  ' defer>
                            <img alt='telegram' src={one} className='w-5 h-5' />
                            <p className=' text-md md:text-xl text-[white] font-[Montserrat-Bold] w-[250px] '>{t('contactus')}</p>
                        </a>
                    </div>
                </div>
                <div className="w-[100%] md:w-[50%] mt-20 md:mt-0 scale-150 md:scale-125">
                    <Carousals index={currentIndex} />
                </div>

            </div>
            {/* Growth Section */}
            <div className="flex md:flex-row flex-col hero_area  justify-between items-center z-50  px-[10%] overflow-hidden pt-20 pb-5 -mt-32 md:-mt-35 mb-10  bg-gradient-to-b from-[#f8f8f800] via-[#ffffff78] to-[#ffffff]  ">
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path
                            id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                        />
                    </defs>
                    <g className="parallax">
                        <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
                <img loading="eager" fetchpriority="high" className='w-[20vh] sm:w-[25vh] md:w-[300px] z-50 ' src={profit} alt='beemi_trivia' />
                <div className="mt-2 z-50 bg-transparent text-xl pt-20 pb-10 md:pb-0 md:text-3xl max-w-[90%] min-w-[90%] md:px-10 text-center md:text-left md:max-w-[65%]  text-[#FF726D] font-[Montserrat-Bold] overflow-hidden">
                    {t('growth')}
                </div>
            </div>
            {/* Discount Section */}
            <div className=" w-full  bg-gradient-to-r from-[#F2419C] to-[#F99F20] py-0 relative flex flex-col justify-center items-center overflow-hidden md:pl-[10%] md:pr-[10%] z-50">
                <h1 className='text-lg md:text-4xl max-w-[70vw] text-white font-bold mt-5 md:mt-10 -mb-10 font-[Montserrat-Bold] leading-5'>{t('discount')}</h1>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    infinite={true}
                    arrows={false}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="w-[100%] "
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    dotListClass="custom-dot-list"
                    itemClass=" w-[100%] py-[2%] flex flex-row justify-center items-center mt-10"
                >
                    {items.current.map((item, index) => (
                        <div key={index} className="bg-white p-2 rounded-lg shadow-md shadow-[#00000025] m-2 min-w-[93%]  flex flex-col items-center">
                            <img src={require(`../../images/affiliates/${item.username}.jpeg`)} alt={`Avatar ${index}`} className="w-32 md:w-44 rounded-full mb-0 border-[5px] border-transparent bg-gradient-to-r from-[#F56967]  to-pink-500 p-[1px]"
                            />
                            <p className="text-xs md:text-xl max-w-[80%] font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 my-5 to-[#F2419C]">{item.username}</p>
                            <button onClick={() => navigateToNextPage(item.username, item.image)} className="mt-auto w-[100%]  bg-gradient-to-r from-yellow-500 to-[#F2419C] rounded-full text-white text-xs md:text-xl py-2 px-0 shadow-md shadow-[#00000049] font-[Montserrat-Bold]">{t("off")}</button>
                        </div>
                    ))}
                </Carousel>
            </div>
            {/* Toutor Section */}
            <div className='w-full bg-gradient-to-r from-[#F34893] to-[#F99F20] px-10%'>
                <div className="flex flex-row items-center w-full h-20 bg-white overflow-hidden  ">
                    <AnimatedComments />
                </div>

                <div className='flex flex-row items-center pt-5 md:pt-10  justify-around'>
                    <div className='flex flex-col  justify-center items-center pt-10'>
                        <h1 className='text-xl md:text-4xl font-[Montserrat-Bold] text-white px-10'>{t(activeTab.title)}</h1>
                        <p className='text-white max-w-[70%] mt-5 font-[Montserrat-Regular]'>{t(activeTab.body)}</p>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-center pb-2 mt-2 mb-5 w-[100%]'>
                    {itemData.map((item, i) => (
                        <div onClick={() => setActiveTabFun(item)} className={`flex flex-col justify-center items-center px-2`}>
                            <div className={` ${item.id == activeTab.id ? "w-28 h-18   bg-white" : "w-20 h-18 "} border-[white] shadow-md border-2 w-[100%] flex justify-center items-center rounded-lg min-h-12`}>
                                <img src={item.id == activeTab.id ? item.icona : item.icon} className={`w-8 h-8`} alt='platform' />
                            </div>

                            <h1 className={` font-[Montserrat-Bold] ${activeTab.id == item.id ? 'text-sm md:text-xl text-white' : 'text-xs md:text-xl  text-[#FFB99F]'} mt-2`}>{t(item.name)}</h1>
                        </div>
                    ))}
                </div>
                <div className='tab-content pb-20'>
                    {activeLoading ? <div>loading</div> : <VedioHeader key={activeTab.id} {...activeTab} />}
                </div>
            </div>
            {/* About Beemi Section */}
            <div className='flex flex-col items-center justify-center md:mx-[5vw] md:pt-12 '>
                <h1 className='text-2xl md:text-6xl font-[Montserrat-Bold] my-5 text-[#F56A67] capitalize'>{t('headerAbout')}</h1>
                <p className='text-md px-8 md:px-0 md:text-xl text-[F56A67] font-[Montserrat-Bold] md:max-w-[50vw] text-[#F56A67]'>{t('headerBody')}</p>
                {items1.map((item, index) => (
                    <div key={index} className={` mb-4 p-5 relative md:w-[85%] overflow-hidden`}>
                        <div className={`absolute ${item.id % 2 == 0 ? "text-right  right-0 " : "text-left left-0"}    px-12 md:px-20 w-[60%] md:w-[50%]   rounded-xl py-10  md:pt-[8%] z-40  `}>
                            <h1 className={`pb-4 font-[Montserrat-Bold] text-white text-xs md:text-3xl capitalize`}>{item.title}</h1>
                            <p className={` md:text-xl text-[5px] font-[Montserrat-Bold]  text-[white] invisible md:visible capitalize`}>{item.body}</p>
                        </div>
                        <img alt={`${item.title}`} src={item.image} className={`w-[100%] `} />
                    </div>
                ))}
            </div>
            {/* Download Section */}
            <div
                className='relative flex flex-col items-center w-[100%] justify-center  bg-gradient-to-r from-[#F34893] to-[#F99F20] text-white'
            >
                <img
                    src={bg}
                    alt="background decoration"
                    className='absolute inset-0 w-full h-full object-cover z-0'
                />
                <div className='relative z-10 mb-10 md:mb-0 md:mt-4'>
                    <div className='justify-center items-center flex flex-col'>
                        <img src={icon} alt="logo" className=' w-32 md:w-48 -mb-16 md:-mb-32 z-50 border-[10px] border-[#42017C] rounded-[40px]' />
                        <div className='flex flex-col justify-center w-[90vw] md:w-[70%] md:h-[45%] border-t-4 px-10 py-5 z-40 rounded-md shadow-xl  bg-gradient-to-b from-[#F34893] to-[#F99F20] pt-10 md:m-10'>
                            <h1 className='text-xl md:text-5xl font-[Montserrat-Bold] mt-20'>{t('triviaCreatetitle')}</h1>
                            <p className='font-[Montserrat-Regular] text-sm md:text-2xl'>{t('triviaCreateBody')}</p>
                            <div className='mt-10'>
                                <button className='px-12 rounded-md shadow-lg py-1 bg-[white] my-0 md:my-5 text-[#F55F74] text-2xl font-[Montserrat-Bold]'>{t('create')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* IOS/Android Download Section */}
            <div id='download' className='bg-white overflow-hidden'>
                <div className="join-section-text">
                    <div className="center-text">
                        <div className='text-[#8D8D8D] font-[Montserrat-Bold] text-3xl' style={{ color: "#8D8D8D" }}>
                            {t('downloadHere')}
                        </div>
                    </div>
                </div>
                {contextHolder}
                {data ?
                    <div className="flex flex-col md:flex-row items-center justify-center w-[100vw] md:mt-10 mb-20" >
                        <GooglePlayButton
                            onClick={() => userEventsListenersGTAG({
                                action: "Download Android",
                                category: "download",
                                label: "download",
                                value: android.current,
                            })}
                            url={android.current}
                            theme={"dark"}
                            className={"custom-style !w-[300px] !h-[80px] md:mt-0 mt-5"}
                        />

                        <AppStoreButton
                            onClick={() => userEventsListenersGTAG({
                                action: "Download IOS",
                                category: "download",
                                label: "download",
                                value: ios.current,
                            })}
                            url={ios.current}
                            theme={"light"}
                            className={"custom-style !w-[300px] !h-[80px] mt-5 md:mt-0 ml-0 md:ml-5"}
                        />
                    </div> : <div className='flex flex-col w-[100%] justify-center py-10'><Spin size='50' /></div>
                }
            </div >
            {/* Contact Us Section */}
            <div className='bg-gradient-to-r from-[#F34893] to-[#F99F20] py-16 w-[100vw]'>
                <h1 className='text-2xl font-[Montserrat-Bold] text-white'>{t('contactus')}</h1>
                <h1 className='text-2xl font-[Montserrat-Regular] text-white'>+251985536686</h1>
            </div>
            {/* contact email Section */}
            <div className=' bg-white'>
                <form className='flex flex-col items-center justify-around ' onSubmit={submitContactForm}>
                    <h1 className="hero-how  !text-[#F5666C]">{t('getintouch')}</h1>
                    <div className="flex flex-col justify-center items-center">
                        <input
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="px-[20vw] md:px-[13vw] rounded-md py-[10px] bg-[#ececec] text-center"
                            placeholder={t('emailenter')}
                            required
                        />
                        <textarea
                            name="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                            placeholder={t('question')}
                            className="px-[20vw] text-center md:px-[13vw] border-none outline-none font-[Montserrat-Regular] rounded-md py-[10px] my-2 bg-[#ececec]"
                        ></textarea>
                        <a className='font-[Montserrat-Bold] text-sm text-[#ff000071]'>{contactError}</a>
                        <button type="submit" className="font-[Montserrat-Bold] md:text-2xl   bg-gradient-to-r from-[#F2419C] to-[#F99F20] text-white px-5 py-2  my-3 rounded-xl w-[200px]    shadow-md shadow-[#2f2f2f68]">{t('submit')}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
