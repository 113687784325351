import React, { useState, useEffect } from 'react';
import OPS from './Opps';
import Great from './Great';
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { firestoreDb } from '../firebase'; // Import modular Firebase functions
import Loading from '../components/BeemiLive/components/Loading';
import { useNavigate } from 'react-router-dom';



const phoneRegex = /^(?:\+2519\d{8}|09\d{8})$/;


export default function ReferalHome() {
  const navigate = useNavigate();


  const [phone, setPhone] = useState(null)
  const [userData, setUserData] = useState({ photo: '', username: '' });
  const [jsxExpression, setJSXExpression] = useState(null)
  const [phoneError, setPhoneError] = useState('');  // Track phone validation error
  const [loading, setLoading] = useState(true);

  const items = React.useRef(
    [
      {
        username: '@muaz_jemal',
        image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/bdc5f7dad859fbae61c39cd237a79203~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=37691&refresh_token=ab02dfe4e3e918e1cdb6ac417626ab12&x-expires=1737190800&x-signature=XVR2%2BfLdHHiLxqPGDulENux%2Fkls%3D&shp=a5d48078&shcp=81f88b70'
      },
      {
        username: '@kbra_a_million',
        image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/c8c30a3dec675e9a17880f77b86f7ca2~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=7370&refresh_token=a1cee68c07164b4aff28dda3f5699eb1&x-expires=1737190800&x-signature=yJWkQKg2sEcTy7G%2F%2BjeWtPX8YMs%3D&shp=a5d48078&shcp=81f88b70'
      },
      {
        username: '@4kilo_entertainment',
        image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/8f45140aaeae6b9be9caa25161d300b5~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=60472&refresh_token=b1ddc9b3d14dce3ceadd831568b58845&x-expires=1737190800&x-signature=XeCcGg3qJ47%2B6tW6sWa5QZUrKFo%3D&shp=a5d48078&shcp=81f88b70'
      },
      {
        username: '@minte.digital',
        image: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/7329091876861149189~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=38302&refresh_token=fa1d74eecf608d2352a3dbba7244bcbb&x-expires=1737190800&x-signature=YxX5RGHET3BO5dKoBcSzqx%2BH2Kc%3D&shp=a5d48078&shcp=81f88b70'
      },
    ]
  );


  const validatePhone = (phone) => {
    return phoneRegex.test(phone);
  };

  const fetchUserData = async () => {
    const pathSegments = window.location.pathname.split('/');
    const username = pathSegments[pathSegments.length - 1];
    const userId = username;


    return {
      username: userId != null ? userId : '@username',
      photo: items.current.find(item => item.username == '@' + username)
    };
  };

  const checkPhoneExists = async (phone) => {
    const q = query(collection(firestoreDb, 'submissions'), where('phone', '==', phone));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty; // Returns true if phone exists, false if not
  };

  useEffect(() => {
    const getUserData = async () => {
      const data = await fetchUserData();      
      setUserData(data);
      
      if (data?.photo == null) {
        // Redirect to the ErrorPage if the user is not found
        navigate('/error/finding/page', { replace: true });
      }
      setLoading(false)
    };

    getUserData();
  }, []);


  const handleSubmit = async () => {

    if (!validatePhone(phone)) {
      setPhoneError('Invalid phone number format');
      return;
    } else {
      setPhoneError('');  // Clear error if phone is valid
    }
    const userList = await checkPhoneExists(phone);

    console.log("phone exists", userList);


    // const userExists = userList.some(user => user.phone === phone);

    if (userList) {
      setJSXExpression(false)

      // alert('Error: The username and phone number already exist.');
    } else {
      try {
        // Simulate an API call
        await setDoc(doc(firestoreDb, 'submissions', phone), {
          referedFrom: userData.username,
          referedImage: userData.photo,
          phone: phone,
          timestamp: serverTimestamp()
        });
        setJSXExpression(true)
        // alert('Success: Phone number is valid.');
      } catch (error) {
        setJSXExpression(false)
        console.log((error));

        // alert('System error: Please try again later.');
      }
    }
  };

  const renderContent = (condition) => {
    if (condition == null) {
      return (
        <div className="flex flex-col items-center h-[100%]">
          <p className="font-bold text-[#F76A68] text-center mb-4 text-xl sm:text-2xl pt-14 px-[20%] md:px-[38%]">Enter your phone number below to get your discount</p>
          <input
            onChange={e => setPhone(e.target.value)}
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder='enter your Phone number here'
            className="rounded-xl border-[#FA8940] outline-none text-center text-bold p-2 w-[70vw] md:w-[20%] mb-4 placeholder-center  border-[3px] text-sm"
          />
          {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}  {/* Show error message */}

          <button onClick={() => handleSubmit()} className="bg-gradient-to-r font-bold from-[#FCA120] to-[#F2419C]  text-white p-2 border-[3px] border-white shadow-lg mt-3 xs:mt-10 shadow-black rounded-3xl w-[35%] md:w-[10%]">
            Submit
          </button>
        </div>
      );
    } else if (condition == true) {
      return <Great />
    } else {
      return <OPS />
    }
  };


  if (loading) return <Loading />


  return (
    <div className="h-[100vh] sm:h-[89vh] w-[100vw] bg-gradient-to-r from-yellow-500 via-pink-500 to-pink-700 overflow-hidden ">
      <div className="flex flex-col items-center justify-center h-[30%] sm:h-[40%]">
        {userData?.username &&
        <img
          src={require(`../images/affiliates/@${userData?.username}.jpeg`)}
          alt="Avatar"
          className="rounded-full sm:w-32 sm:h-32 w-20 h-20 bg-[white]"
        />}
        <p className="mt-4 text-white">@{userData.username}</p>
      </div>
      <div className="flex-grow bg-white xs:h-[60%] h-[80%] rounded-t-[80%] relative right-[25vw] w-[150vw] bottom-0">
        {renderContent(jsxExpression)}
      </div>
    </div>
  );
}
