import React from 'react';
import './boost-engagment.css';

const BouncingAnimation = () => {
  return (
    <div className="container-animation m-auto">
      <div className="holder" style={{ top: "25%", left: "20%" }}>
        <div className="phone">
          <div className="holder" style={{ top: "10%", left: "50%", scale: "1.6" }}>
            {[
              { top: "35%", left: "87%", scale: "0.9", delay: "3.5s", img: require('./assets/trivia_usr_ans_2.webp') },
              { top: "61%", left: "120%", scale: "0.9", delay: "2.8s", img: require('./assets/trivia_usr_ans_3.webp') },
              { top: "17%", left: "104%", scale: "0.7", delay: "3s", img: require('./assets/trivia_usr_ans_4.webp') },
              { top: "53%", left: "87%", scale: "0.3", delay: "2.5s", img: require('./assets/trivia_usr_ans_5.webp') },
              { top: "-3%", left: "100%", scale: "0.4", delay: "2s", img: require('./assets/trivia_usr_ans_5.webp') },
              { top: "1%", left: "73%", scale: "0.7", delay: "2.7s", img: require('./assets/trivia_usr_ans_6.webp') },
              { top: "11%", left: "73%", scale: "0.5", delay: "2.3s", img: require('./assets/trivia_usr_ans_7.webp') },
            ].map((item, i) => (
              <div key={i} className="holder" style={{ top: item.top, left: item.left, scale: item.scale }}>
                <div
                  className="trivia_usr_ans_bg trivia_usr_ans_bg_blurred"
                  style={{
                    backgroundImage: `url(${item.img})`,
                    animationDelay: item.delay,
                  }}
                />
              </div>
            ))}
          </div>

          <div className="holder" style={{ top: "20%", left: "22%" }}>
            <div className="trivia_bg" />
          </div>

          {[
            { top: "45%", left: "18%", delay: "0.1s" },
            { top: "41%", left: "48%", delay: "0.2s" },
            { top: "59%", left: "23%", delay: "0.4s" },
            { top: "55%", left: "53%", delay: "0.5s" },
          ].map((item, i) => (
            <div key={`ans${i}`} className="holder" style={{ top: item.top, left: item.left }}>
              <div className={`trivia_ans_${i}`} style={{ animationDelay: item.delay }} />
            </div>
          ))}

          {[
            { top: "48%", left: "92%", scale: "0.9", delay: "1.2s", img: require('./assets/trivia_usr_ans_1.webp') },
            { top: "35%", left: "87%", scale: "0.9", delay: "1.5s", img: require('./assets/trivia_usr_ans_2.webp') },
            { top: "61%", left: "120%", scale: "0.9", delay: "1.8s", img: require('./assets/trivia_usr_ans_3.webp') },
            { top: "17%", left: "104%", scale: "0.7", delay: "2.1s", img: require('./assets/trivia_usr_ans_4.webp') },
            { top: "53%", left: "87%", scale: "0.3", delay: "2s", img: require('./assets/trivia_usr_ans_5.webp') },
            { top: "-3%", left: "100%", scale: "0.4", delay: "1.6s", img: require('./assets/trivia_usr_ans_5.webp') },
            { top: "4%", left: "73%", scale: "0.7", delay: "1.9s", img: require('./assets/trivia_usr_ans_6.webp') },
            { top: "11%", left: "73%", scale: "0.5", delay: "1.4s", img: require('./assets/trivia_usr_ans_7.webp') },
          ].map((item, i) => (
            <div key={`usr${i}`} className="holder" style={{ top: item.top, left: item.left, scale: item.scale }}>
              <div
                className="trivia_usr_ans_bg"
                style={{
                  backgroundImage: item.img ? `url(${item.img})` : undefined,
                  animationDelay: item.delay,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BouncingAnimation;
