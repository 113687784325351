import React, { lazy, Suspense } from 'react'
import './App.css';
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './components/pages/Contact';
import LayoutMain from './components/LayoutMain';
import logo from './Referral/assets/beemi.svg'
import ReferalHome from './Referral';
import { HelmetProvider } from 'react-helmet-async';
import HashLoader from "react-spinners/HashLoader";

const BeemiLive = lazy(() => import('./components/BeemiLive'));
const MoreGifts = lazy(() => import('./components/BeemiLive/components/OnGift'));
const Great = lazy(() => import('./components/BeemiLive/components/Great'));
const MGifts = lazy(() => import('./components/MGifts'));
const GiftList = lazy(() => import('./components/BeemiLive/components/GiftDetial'));
const Referral = lazy(() => import('./Referral'));




const SimpleLayout = ({ children }) => {
  return (
    <div className="h-[100vh]">
      <header className="w-full h-16 sm:h-24 flex justify-center items-center " >
        <img className="h-14 sm:h-24 -ml-6" src={logo} alt="logo" />
      </header>
      <ReferalHome />
    </div>
  );
}
const ErrorPage = () => {
  return (
    <div className="h-[100vh] flex flex-col justify-center items-center">
      {/* <img className="h-20 sm:h-24 mb-0 -ml-20" src={logo} alt="logo" /> */}
      <h1 className="text-2xl sm:text-4xl mb-4 font-[Montserrat-Bold] uppercase">Page Not Found</h1>
      <a
        className="px-4 py-2 bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white rounded"
        href='/'
      >
        Go Back
      </a>
    </div>
  );
};


function App() {

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<LayoutMain />}>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gifts" element={
              <Suspense fallback={<div className='flex flex-col justify-center items-center w-[100vw] h-[100vh]'>
                <HashLoader size={100} color='#F34893' />
              </div>}>
                <BeemiLive />
              </Suspense>
            } />
            <Route path="/gift/:username" element={
              <Suspense fallback={<div className='flex flex-col justify-center items-center w-[100vw] h-[100vh]'>
                <HashLoader size={100} color='#F34893' />
              </div>}>
                <MoreGifts />
              </Suspense>
            } />
            <Route path='/gift/:username/:giftId' element={
              <Suspense fallback={<div className='flex flex-col justify-center items-center w-[100vw] h-[100vh]'>
                <HashLoader size={100} color='#F34893' />
              </div>}>
                <GiftList />
              </Suspense>
            } />
            <Route path='/done/:channel' element={
              <Suspense fallback={<div className='flex flex-col justify-center items-center w-[100vw] h-[100vh]'>
                <HashLoader size={100} color='#F34893' />
              </div>}>
                <Great />
              </Suspense>
            } />
            <Route exact path="/my-gifts" element={
              <Suspense fallback={<div className='flex flex-col justify-center items-center w-[100vw] h-[100vh]'>
                <HashLoader size={100} color='#F34893' />
              </div>}>
                <MGifts />
              </Suspense>
            } />
          </Route>
          <Route element={<SimpleLayout />}>
            <Route exact path="/:username" element={<Referral />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );


}

export default App;
